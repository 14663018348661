@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
@import 'node_modules/bootstrap/scss/bootstrap.scss';

* {
  font-family: 'Poppins', sans-serif;
}

html {
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700 !important;
}

a {
  cursor: pointer;
}

*:disabled {
  cursor: not-allowed;
}

/* Hide arrows from input numbers */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}
/* End of hide arrows from input numbers */

html {
  font-size: 1rem;
}

h2 {
  font-size: 2.5rem;
}

h3,
.large {
  font-size: 1.25rem;
}

@include media-breakpoint-down(sm) {
  h1,
  h2 {
    font-size: 1.6rem;
  }

  h3,
  .large {
    font-size: 0.9rem;
  }

  h6 {
    font-size: 0.95rem;
  }

  .navbar-brand {
    margin-right: 0;
    strong {
      font-size: 1.2rem;
      margin-left: 5px;
    }
  }

  small {
    font-size: 0.7rem;
  }
}
//
//@include media-breakpoint-up(md) {
//    html {
//        font-size: 1.4rem;
//    }
//}
//
//@include media-breakpoint-up(lg) {
//    html {
//        font-size: 1.6rem;
//    }
//}
